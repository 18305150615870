/* eslint-disable @typescript-eslint/no-explicit-any */
import "../styles/app.scss";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import { DefaultSeo } from "next-seo";
import { SWRConfig } from "swr";
import Modal from "react-modal";
import { NextIntlProvider } from "next-intl";

Modal.setAppElement("#__next");

export default function Artilleriet({ Component, pageProps }) {
  const router = useRouter();
  // const { asPath } = useRouter();

  const [pageLoadedOnce, setPageLoadedOnce] = useState(false);

  // Google Tag Manager
  useEffect(() => {
    const handleRouteChange = (url) => {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "pageview",
        event_id: new Date().getTime(),
        pagePath: url,
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    function onLoad() {
      setPageLoadedOnce(true);
    }

    window.addEventListener("load", onLoad);
    const timeoutId = setTimeout(onLoad, 1500);

    return () => {
      window.removeEventListener("load", onLoad);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    function setCssVariables() {
      document.documentElement.style.setProperty(
        "--scrollbar-width",
        window.innerWidth - document.documentElement.clientWidth + "px"
      );
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      const headerTop = document.querySelector(".Header-top");
      const header = document.querySelector(".Header-content");

      if (header) {
        const headerCoords = header.getBoundingClientRect();
        let headerHeight = headerCoords.height;

        if (headerTop) {
          const headerTopCoords = headerTop.getBoundingClientRect();
          headerHeight += headerTopCoords.height;

          document.documentElement.style.setProperty(
            "--top-header-height",
            headerTopCoords.height + "px"
          );
        }

        document.documentElement.style.setProperty(
          "--header-height",
          headerHeight + "px"
        );
      }
    }

    window.addEventListener("resize", setCssVariables);
    window.addEventListener("load", setCssVariables);
    setCssVariables();

    return () => {
      window.removeEventListener("resize", setCssVariables);
      window.removeEventListener("load", setCssVariables);
    };
  }, []);

  return (
    <NextIntlProvider messages={pageProps.messages}>
      <DefaultSeo
        title="Artilleriet"
        additionalMetaTags={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1, maximum-scale=1",
          },
          {
            name: "p:domain_verify",
            content: "46169a8751bb2b97a18cd2eb49dea0be",
          },
          {
            name: "p:domain_verify",
            content: "226e7c042657c582f35700aeeb66636d",
          },
          {
            name: "facebook-domain-verification",
            content: "o5pbfh1t3832glgmetzd854hr6ytq0",
          },
          {
            name: "google-site-verification",
            content: "_rjISYc69l27CdJC3y1MsbcCoa0eWc1qXvQBe86cRjs",
          },
        ]}
        additionalLinkTags={[
          {
            rel: "icon",
            href: "/favicon.ico",
          },
          {
            rel: "preconnect",
            href: "https://artilleriet.centra.com",
          },
          {
            rel: "preconnect",
            href: "https://artilleriet.centracdn.net",
          },
          {
            rel: "preload",
            href: "https://cdn.depict.ai/artilleriet-dev/modern.js",
            as: "fetch",
            crossOrigin: "anonymous",
          },
          {
            rel: "alternate",
            hrefLang: "x-default",
            href: "https://artilleriet.se" + router.asPath.replace(/\/$/, ""),
          } as any,
          {
            rel: "alternate",
            hrefLang: "sv-se",
            href: "https://artilleriet.se/sv" + router.asPath.replace(/\/$/, ""),
          } as any,
        ]}
      />

      {!pageProps.isWorkingInStoryblok && (
        <Script
          id="GA4"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'G-EXP2D9YKTP');`,
          }}
        />
      )}

      {!pageProps.isWorkingInStoryblok && (
        <Script
          id="voyado-web-activity-tracking"
          dangerouslySetInnerHTML={{
            __html: `(function (i, s, o, g, r, a, m) {
            i['VoyadoAnalyticsObject'] = r;
            i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date();
            a = s.createElement(o), m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://assets.voyado.com/jsfiles/analytics_0.1.6.min.js', 'va'); 
            
        va("setTenant", "artilleriet");
        `,
          }}
        />
      )}

      {!pageProps.isWorkingInStoryblok && (
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EXP2D9YKTP"
        />
      )}


      {!pageProps.isWorkingInStoryblok && (
        <Script
          id="Cookiebot"
          data-culture={router.locale}
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="8d0c4945-1f41-48d6-851a-738eac299085"
          data-blockingmode="auto"
          type="text/javascript"
        />
      )}

      {!pageProps.isWorkingInStoryblok && (
        <Script
          id="gtm"
          data-cookieconsent="statistics"
          type="text/plain"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MG9QMT');`,
          }}
        />
      )}

      {!pageProps.isWorkingInStoryblok && (
        <Script
          id="gtm_2"
          type="text/plain"
          data-cookieconsent="statistics"
          src="https://www.googletagmanager.com/gtag/js?id=AW-718773704"
        />
      )}


      <Script
        id="depict"
        dangerouslySetInnerHTML={{
          __html: `!function(){var e,n,t={merchant:"artilleriet"},i=!1;try{var o,d,l,a,c,r=document.createElement("iframe");r.src="about:blank",document.head.appendChild(r);var u=null==r||null===(o=r.contentWindow)||void 0===o||null===(d=o.String)||void 0===d||null===(l=d.prototype)||void 0===l||null===(a=l.replaceAll)||void 0===a||null===(c=a.toString)||void 0===c?void 0:c.call(a);r.parentNode.removeChild(r),u&&u.indexOf("[native code]")>-1&&(i=!0)}catch(e){}function p(e){var n=new XMLHttpRequest;n.addEventListener("load",(function(){new Function("options",n.responseText)(t)})),n.open("GET","https://cdn.depict.ai/"+t.merchant+"/"+e+".js"),n.send()}(e=window).dpc||(e.dpc=(n=function e(){var n=arguments;e.send_event?e.send_event.apply(this,n):e.queue.push(n)},n.queue=[],n)),p(i?"modern":"compatibility")}();`,
        }}
      />

      <Script
        id="mcjs"
        type="text/plain"
        data-cookieconsent="marketing"
        dangerouslySetInnerHTML={{
          __html: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/360e73d4374120070c2f2ea54/55bcd1f345ed2ec24122694f3.js");`,
        }}
      />

      <SWRConfig
        value={{
          fallback: pageProps.swrFallback || {},
          revalidateOnFocus: false,
        }}
      >
        <Component pageLoadedOnce={pageLoadedOnce} {...pageProps} />
      </SWRConfig>
    </NextIntlProvider>
  );
}
